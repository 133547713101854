
	melon.templates['workspace_sidebar_loading_skeleton'] = `<div class="workspace-sidebar-skeleton">
	<div class="widget-group-body">
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box child skeleton-card"></div>
		<div class="widget sidebar-box child skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
	</div>
	<div class="widget-group-body">
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box child skeleton-card"></div>
		<div class="widget sidebar-box child skeleton-card"></div>
		<div class="widget sidebar-box child skeleton-card"></div>
		<div class="widget sidebar-box child skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
		<div class="widget sidebar-box skeleton-card"></div>
	</div>
</div>`;
