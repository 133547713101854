melon.provide("melon.ui.misc");
melon.ui.misc.about = function () {
	if (!melon.ui.misc.about_dialog) {
		var d = new melon.ui.Dialog({ title: __("Monak ERP") });

		$(d.body).html(
			repl(
				`<div>
					<p>${__("Web based Integrated Enterprise Information System (IEIS)")}</p>
					<p><i class='fa fa-globe fa-fw'></i>
						${__("Website")}:
						<a href='https://monakerp.com' target='_blank'>https://monakerp.com</a></p>
					<hr>
					<h4>${__("Installed Apps")}</h4>
					<div id='about-app-versions'>${__("Loading versions...")}</div>
					<hr>
					<p class='text-muted'>${__("&copy; Alphamonak Solutions")} </p>
					</div>`,
				melon.app
			)
		);

		melon.ui.misc.about_dialog = d;

		melon.ui.misc.about_dialog.on_page_show = function () {
			if (!melon.versions) {
				melon.call({
					method: "melon.utils.change_log.get_versions",
					callback: function (r) {
						show_versions(r.message);
					},
				});
			} else {
				show_versions(melon.versions);
			}
		};

		var show_versions = function (versions) {
			var $wrap = $("#about-app-versions").empty();
			$.each(Object.keys(versions).sort(), function (i, key) {
				var v = versions[key];
				var text = $.format("<p><b>{0}:</b> v{1}<br></p>", [v.title, v.version]);
				$(text).appendTo($wrap);
			});

			melon.versions = versions;
		};
	}

	melon.ui.misc.about_dialog.show();
};
